import React from 'react'
import Footer from '../components/footer/Footer'
import Dashboard from '../components/editor/dashboard/Dashboard'

function Home() {

    
 
    return (
        <div>
            
            <Dashboard/>
            <Footer/>

        </div>
    )
}

export default Home
