import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";

import "bulma";
import 'bulma-switch';
import './style/index.scss';
import App from './App';

axios.defaults.withCredentials = true


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

