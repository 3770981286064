import React from "react";
import "./footer.scss";
import logo from "../../data/images/logo_white.png";



function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <footer className="footer has-background-dark has-text-white pb-5">
        <div className="columns">
          <div className="column is-6">
            <div className="" style={{ display: "inline-flex" }}>
              <img src={logo} width="50px" alt="Validata" />
              <br />
              <p><h1 className="title is-2 has-text-white ml-4"><a className="footer_link" href="https://lukasstoll.com" >Lukas Stoll</a></h1></p>
            </div>
            <p>
            Price calculation for your accommodation in Amsterdam.
            </p>


          </div>
          <div className="column is-2">
            <h2 className="title is-6 has-text-white">Contact</h2>
            <ul className="footer-link">
              <li><a className="footer_link" href="https://lukasstoll.com" >Lukas Stoll</a></li>

            </ul>
          </div>
        </div>
      </footer>
      <div className="has-text-white pt-2 pb-2" style={{ backgroundColor: "#0E1117" }}>
        <div className="has-text-centered">
          <p>© Validata {currentYear}. Made with ❤️ by Lukas.</p>
        </div>
      </div>

    </div>
  );
}

export default Footer;
