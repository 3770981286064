import React, { useState, useRef } from 'react'
//import juniorsoccer from '../../../data/images/undraw_junior_soccer_6sop.svg'
import './Dashboard.scss'
import axios from 'axios'
import { Ellipsis } from 'react-spinners-css';
import image1 from "../../../data/images/room/room1.jpg"
import image2 from "../../../data/images/room/room2.jpg"
import image3 from "../../../data/images/room/room3.jpg"
import stack from "../../../data/images/stack.png"
import result from "../../../data/images/result.png"

var random = Math.floor(Math.random() * 3) + 1;
var image;
if (random === 1) {
    image = image1
}
else if (random === 2) {
    image = image2
}
else {
    image = image3
}


function Dashboard() {

    const explanation = useRef(null)
    const outlook = useRef(null)


    const [activeModal, setActiveModal] = useState('');


    const [buttonDisabled, setButtonDisabled] = useState(false);

    // Superhost
    const superhost = ["Yes", "No"]
    const [isHoverSuperhost, setIsHoverSuperhost] = useState(false);
    const [selectedSuperhost, setSelectedSuperhost] = useState("Yes");
    const handlePositionTypeChange = (e) => {
        setSelectedSuperhost(e.target.value)
    }

    // Accomodates
    const accomodates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // eslint-disable-next-line
    const [isHoverAccomodates, setIsHoverAccomodates] = useState(false);
    const [selectedAccomodates, setSelectedAccomodates] = useState(1);
    const handlePositionTypeChangeAccomodates = (e) => {
        setSelectedAccomodates(e.target.value)
    }

    // bedrooms
    const bedrooms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // eslint-disable-next-line
    const [isHoverbedrooms, setIsHoverbedrooms] = useState(false);
    const [selectedbedrooms, setSelectedbedrooms] = useState(1);
    const handlePositionTypeChangebedrooms = (e) => {
        setSelectedbedrooms(e.target.value)
    }

    // beds
    const beds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // eslint-disable-next-line
    const [isHoverbeds, setIsHoverbeds] = useState(false);
    const [selectedbeds, setSelectedbeds] = useState(1);
    const handlePositionTypeChangebeds = (e) => {
        setSelectedbeds(e.target.value)
    }


    // 'review_scores_cleanliness',
    const review_scores_cleanliness = [1, 2, 3, 4, 5]
    // eslint-disable-next-line
    const [isHoverreview_scores_cleanliness, setIsHoverreview_scores_cleanliness] = useState(false);
    const [selectedreview_scores_cleanliness, setSelectedreview_scores_cleanliness] = useState(1);
    const handlePositionTypeChangereview_scores_cleanliness = (e) => {
        setSelectedreview_scores_cleanliness(e.target.value)
    }
    // 'reviews_per_month',
    const reviews_per_month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // eslint-disable-next-line
    const [isHoverreviews_per_month, setIsHoverreviews_per_month] = useState(false);
    const [selectedreviews_per_month, setSelectedreviews_per_month] = useState(1);
    const handlePositionTypeChangereviews_per_month = (e) => {
        setSelectedreviews_per_month(e.target.value)
    }


    // 'Heating',
    const Heating = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverHeating, setIsHoverHeating] = useState(false);
    const [selectedHeating, setSelectedHeating] = useState("No");
    const handlePositionTypeChangeHeating = (e) => {
        setSelectedHeating(e.target.value)
    }
    // Hair dryer',
    const HairDryer = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverHairDryer, setIsHoverHairDryer] = useState(false);
    const [selectedHairDryer, setSelectedHairDryer] = useState("No");
    const handlePositionTypeChangeHairDryer = (e) => {
        setSelectedHairDryer(e.target.value)
    }
    // Hot water',
    const HotWater = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverHotWater, setIsHoverHotWater] = useState(false);
    const [selectedHotWater, setSelectedHotWater] = useState("No");
    const handlePositionTypeChangeHotWater = (e) => {
        setSelectedHotWater(e.target.value)
    }

    // Private entrance',
    const PrivateEntrance = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverPrivateEntrance, setIsHoverPrivateEntrance] = useState(false);
    const [selectedPrivateEntrance, setSelectedPrivateEntrance] = useState("No");
    const handlePositionTypeChangePrivateEntrance = (e) => {
        setSelectedPrivateEntrance(e.target.value)
    }

    // 'Bathtub',
    const Bathtub = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverBathtub, setIsHoverBathtub] = useState(false);
    const [selectedBathtub, setSelectedBathtub] = useState("No");
    const handlePositionTypeChangeBathtub = (e) => {
        setSelectedBathtub(e.target.value)
    }
    // 'Elevator',
    const Elevator = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverElevator, setIsHoverElevator] = useState(false);
    const [selectedElevator, setSelectedElevator] = useState("No");
    const handlePositionTypeChangeElevator = (e) => {
        setSelectedElevator(e.target.value)
    }
    // 'Air conditioning',
    const AirConditioning = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverAirConditioning, setIsHoverAirConditioning] = useState(false);
    const [selectedAirConditioning, setSelectedAirConditioning] = useState("No");
    const handlePositionTypeChangeAirConditioning = (e) => {
        setSelectedAirConditioning(e.target.value)
    }
    // 'Security cameras on property',
    const SecurityCameras = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverSecurityCameras, setIsHoverSecurityCameras] = useState(false);
    const [selectedSecurityCameras, setSelectedSecurityCameras] = useState("No");
    const handlePositionTypeChangeSecurityCameras = (e) => {
        setSelectedSecurityCameras(e.target.value)
    }

    const EntireHome = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverEntireHome, setIsHoverEntireHome] = useState(false);
    const [selectedEntireHome, setSelectedEntireHome] = useState("No");
    const handlePositionTypeChangeEntireHome = (e) => {
        setSelectedEntireHome(e.target.value)
    }
    // 'Private room'

    const PrivateRoom = ["No", "Yes"]
    // eslint-disable-next-line
    const [isHoverPrivateRoom, setIsHoverPrivateRoom] = useState(false);
    const [selectedPrivateRoom, setSelectedPrivateRoom] = useState("No");
    const handlePositionTypeChangePrivateRoom = (e) => {
        setSelectedPrivateRoom(e.target.value)
    }


    // district
    const district = ["Bos en Lommer", "Centrum-Oost", "Centrum-West", "De Baarsjes - Oud-West", "De Pijp - Rivierenbuurt",
        "Oud-Noord", "Oud-Oost", "Westerpark", "Zuid"]
    // eslint-disable-next-line
    const [isHoverdistrict, setIsHoverdistrict] = useState(false);
    const [selecteddistrict, setSelecteddistrict] = useState("Bos en Lommer");
    const handlePositionTypeChangedistrict = (e) => {
        setSelecteddistrict(e.target.value)
    }




    const getWelcomeTime = () => {
        var n = new Date().getHours();
        if (n >= 5 && n < 12) {
            return "Good Morning"
        }
        else if (n >= 12 && n < 18) {
            return "Good afternoon"
        }
        else {
            return "Good evening"
        }
    }

    const getYesOrNo = (word) => {
        if (word === "Yes")
            return 1
        else
            return 0
    }

    const getCity = (city, selected) => {
        if (city === selected)
            return 1
        else
            return 0
    }

    const [loading, setLoading] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [price, setPrice] = useState()
    const generatePrice = async () => {
        setLoading(true)
        setShowPrice(false);
        setShowFeedback(false)
        setThanks(false)
        //var waiting = Math.floor(Math.random() * 10) + 1;
        var superhost = 0;
        if (selectedSuperhost === "Yes")
            superhost = 1;
        var accomodates = selectedAccomodates;
        var bedrooms = selectedbedrooms;
        var beds = selectedbeds;
        var review_scores_cleanliness = selectedreview_scores_cleanliness;
        var reviews_per_month = selectedreviews_per_month;
        var heating = getYesOrNo(selectedHeating);
        var hairDryer = getYesOrNo(selectedHairDryer);
        var hotWater = getYesOrNo(selectedHotWater);
        var privateEntrance = getYesOrNo(selectedPrivateEntrance);
        var bathtub = getYesOrNo(selectedBathtub);
        var elevator = getYesOrNo(selectedElevator);
        var airConditioning = getYesOrNo(selectedAirConditioning);
        var securityCameras = getYesOrNo(selectedSecurityCameras);
        var entrieHome = getYesOrNo(selectedEntireHome);
        var privateRoom = getYesOrNo(selectedPrivateRoom);



        var bosenLommer = getCity("Bos en Lommer", selecteddistrict)
        var centrumOost = getCity("Centrum-Oost", selecteddistrict)
        var centrumWest = getCity("Centrum-West", selecteddistrict)
        var deBaarsjes = getCity("De Baarsjes - Oud-West", selecteddistrict)
        var dePijpRivierenbuurt = getCity("De Pijp - Rivierenbuurt", selecteddistrict)
        var oudNoord = getCity("Oud-Noord", selecteddistrict)
        var oudOost = getCity("Oud-Oost", selecteddistrict)
        var westerpark = getCity("Westerpark", selecteddistrict)
        var zuid = getCity("Zuid", selecteddistrict)


        const data = {
            superhost: superhost,
            accomodates: accomodates,
            bedrooms: bedrooms,
            beds: beds,
            review_scores_cleanliness: review_scores_cleanliness,
            reviews_per_month: reviews_per_month,
            heating: heating,
            hairDryer: hairDryer,
            hotWater: hotWater,
            privateEntrance: privateEntrance,
            bathtub: bathtub,
            elevator: elevator,
            airConditioning: airConditioning,
            securityCameras: securityCameras,
            entrieHome: entrieHome,
            privateRoom: privateRoom,
            bosenLommer: bosenLommer,
            centrumOost: centrumOost,
            centrumWest: centrumWest,
            deBaarsjes: deBaarsjes,
            oudOost: oudOost,
            dePijpRivierenbuurt: dePijpRivierenbuurt,
            oudNoord: oudNoord,
            westerpark: westerpark,
            zuid: zuid,
        }

        console.log(data)
        console.log(Object.keys(data).length);

        axios
            .post(`${process.env.REACT_APP_API_URL}/price/post/prediction/`, data
            )
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    setPrice(res.data.price)
                    setButtonDisabled(false);
                    setShowPrice(true);
                    //await new Promise(resolve => setTimeout(resolve, waiting * 1000)); // 3 sec
                    setShowFeedback(true)
                    setThanks(false)

                }
            })
            .catch((error) => {
                setButtonDisabled(false);
                setLoading(false)
                console.log(error);

            });


    }

    const [collapsible, setCollapsible] = useState(false)
    const [thanks, setThanks] = useState(false);

    return (
        <div>

            {/* <section className="hero welcome is-small background-gradient has-text-white">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title has-text-white">
                            {getWelcomeTime()}, Airbn🍺ler.
                        </h1>
                        <h2 className="subtitle has-text-white">
                            Wir wünschen Viel Spaß beim perfekten Preis finden!
                        </h2>
                    </div>
                </div>
            </section> */}


            <section className="hero-section" style={{ backgroundImage: `url(${image})` }}>

                <div className="hero-row">
                    <div className="ml-5 hero-text">
                        <h1 className="title has-text-white is-1">
                            {getWelcomeTime()}, Airbn🍺ler<span className="home__dot">.</span>
                        </h1>
                        <h2 className="subtitle has-text-white">
                            Have fun finding the perfect price!
                        </h2>
                    </div>
                </div>

            </section>


            <div className="info-tiles ml-5 mt-5 mb-5 mr-5">

                <section className="info-tiles">
                    <br />
                    <h4 className="title is-4">Current prices at a glance</h4>
                    <p className="subtitle is-6">Take a quick look at our price finder!  </p>

                </section>


                <br />
                <section style={{ width: '50%' }}>
                    <article className="message is-success">
                        <div className="message-body">
                            Please note that preselections have already been made.
                        </div>
                    </article>
                </section>


                <div className="mt-5">
                    <div className="columns is-multiline">
                        <div className="column is-3">
                            <p className="mb-2"><strong>Superhost</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverSuperhost === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverSuperhost(true)}
                                            onMouseLeave={() => setIsHoverSuperhost(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span>

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>A superhost is a person who goes way above and beyond in their hosting duties. They exhibit a high level of excellence in cleanliness, friendliness and so on.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChange(e)} style={{ width: "100%" }}>
                                    {
                                        superhost.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="column is-3">
                            <p className="mb-2"><strong>Guests</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverAccomodates === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        {/* <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverAccomodates(true)}
                                            onMouseLeave={() => setIsHoverAccomodates(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span> */}

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChangeAccomodates(e)} style={{ width: "100%" }}>
                                    {
                                        accomodates.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="column is-3">
                            <p className="mb-2"><strong>Bedroom</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverbedrooms === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        {/* <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverbedrooms(true)}
                                            onMouseLeave={() => setIsHoverbedrooms(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span> */}

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChangebedrooms(e)} style={{ width: "100%" }}>
                                    {
                                        bedrooms.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="column is-3">
                            <p className="mb-2"><strong>Beds</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverbeds === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        {/* <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverbeds(true)}
                                            onMouseLeave={() => setIsHoverbeds(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span> */}

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChangebeds(e)} style={{ width: "100%" }}>
                                    {
                                        beds.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="column is-3">
                            <p className="mb-2"><strong>Cleanliness rating</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverreview_scores_cleanliness === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        {/* <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverreview_scores_cleanliness(true)}
                                            onMouseLeave={() => setIsHoverreview_scores_cleanliness(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span> */}

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChangereview_scores_cleanliness(e)} style={{ width: "100%" }}>
                                    {
                                        review_scores_cleanliness.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="column is-6">
                            <p className="mb-2"><strong>Average rating per month</strong>


                                <div className={`dropdown ml-2 is-up ${isHoverreviews_per_month === true ? "is-active" : null}`}>
                                    <div className="dropdown-trigger">


                                        {/* <span className="tag is-info"
                                            onMouseEnter={() => setIsHoverreviews_per_month(true)}
                                            onMouseLeave={() => setIsHoverreviews_per_month(false)}>
                                            <i className="fas fa-info-circle"></i>
                                        </span> */}

                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                        <div className="dropdown-content">
                                            <div className="dropdown-item">
                                                <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <div className="select">
                                <select onChange={e => handlePositionTypeChangereviews_per_month(e)} style={{ width: "100%" }}>
                                    {
                                        reviews_per_month.map((day, index) => (
                                            <option key={index}>{day}</option>

                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                    </div>
                    <strong className="mb-3">Additional settings</strong>
                    <section className="mb-5 further">
                        <div className="m-2">
                            <div onClick={() => setCollapsible(!collapsible)}>
                                Filter
                                {
                                    collapsible === false ?
                                        <i className="fas fa-chevron-down has-text-primary ml-2"></i>
                                        :
                                        <i className="fas fa-chevron-up has-text-primary ml-2"></i>
                                }
                            </div>

                            {
                                collapsible &&
                                <div>
                                    <div>
                                        <p className="mt-5" style={{ color: "#c2c2c2", marginBottom: "0.1rem" }}>Features</p>
                                        <hr className="mt-1" />
                                        <div className="columns is-multiline">
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Heating</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverHeating === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverHeating(true)}
                                                                onMouseLeave={() => setIsHoverHeating(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeHeating(e)} style={{ width: "100%" }}>
                                                        {
                                                            Heating.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Hairdryer</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverHairDryer === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverHairDryer(true)}
                                                                onMouseLeave={() => setIsHoverHairDryer(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeHairDryer(e)} style={{ width: "100%" }}>
                                                        {
                                                            HairDryer.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Hot water</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverHotWater === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverHotWater(true)}
                                                                onMouseLeave={() => setIsHoverHotWater(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeHotWater(e)} style={{ width: "100%" }}>
                                                        {
                                                            HotWater.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Bathtub</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverBathtub === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverBathtub(true)}
                                                                onMouseLeave={() => setIsHoverBathtub(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeBathtub(e)} style={{ width: "100%" }}>
                                                        {
                                                            Bathtub.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Lift</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverElevator === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverElevator(true)}
                                                                onMouseLeave={() => setIsHoverElevator(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeElevator(e)} style={{ width: "100%" }}>
                                                        {
                                                            Elevator.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Air conditioning</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverAirConditioning === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverAirConditioning(true)}
                                                                onMouseLeave={() => setIsHoverAirConditioning(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeAirConditioning(e)} style={{ width: "100%" }}>
                                                        {
                                                            AirConditioning.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                        <p className="mt-5" style={{ color: "#c2c2c2", marginBottom: "0.1rem" }}>City borough</p>
                                        <hr className="mt-1" />
                                        <div className="columns is-multiline">
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Private entrance</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverPrivateEntrance === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverPrivateEntrance(true)}
                                                                onMouseLeave={() => setIsHoverPrivateEntrance(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangePrivateEntrance(e)} style={{ width: "100%" }}>
                                                        {
                                                            PrivateEntrance.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>City district</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverdistrict === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverdistrict(true)}
                                                                onMouseLeave={() => setIsHoverdistrict(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangedistrict(e)} style={{ width: "100%" }}>
                                                        {
                                                            district.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Complete home/apartment</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverEntireHome === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverEntireHome(true)}
                                                                onMouseLeave={() => setIsHoverEntireHome(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeEntireHome(e)} style={{ width: "100%" }}>
                                                        {
                                                            EntireHome.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Private room</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverPrivateRoom === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverPrivateRoom(true)}
                                                                onMouseLeave={() => setIsHoverPrivateRoom(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangePrivateRoom(e)} style={{ width: "100%" }}>
                                                        {
                                                            PrivateRoom.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="column is-3">
                                                <p className="mb-2"><strong>Surveillance cameras</strong>


                                                    <div className={`dropdown ml-2 is-up ${isHoverSecurityCameras === true ? "is-active" : null}`}>
                                                        <div className="dropdown-trigger">


                                                            {/* <span className="tag is-info"
                                                                onMouseEnter={() => setIsHoverSecurityCameras(true)}
                                                                onMouseLeave={() => setIsHoverSecurityCameras(false)}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span> */}

                                                        </div>
                                                        <div className="dropdown-menu" id="dropdown-menu7" role="menu">
                                                            <div className="dropdown-content">
                                                                <div className="dropdown-item">
                                                                    <p>You can add the <code>is-up</code> modifier to have a dropdown menu that appears above the dropdown button.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </p>
                                                <div className="select">
                                                    <select onChange={e => handlePositionTypeChangeSecurityCameras(e)} style={{ width: "100%" }}>
                                                        {
                                                            SecurityCameras.map((day, index) => (
                                                                <option key={index}>{day}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            }

                        </div>
                    </section>


                    <button className="button is-primary" disabled={buttonDisabled===true} onClick={() => {generatePrice(); setButtonDisabled(true)}}>Generate price&nbsp; <i className="fas fa-calculator"></i></button>
                    {
                        loading &&
                        <div>
                            <Ellipsis color="#5869FF" style={{ left: "50", right: "50", top: "50", bottom: "50", }} />
                            <p>Price loads...</p>
                        </div>
                    }
                    {
                        showPrice &&
                        <p className="mt-5">The Proposed Price is: <strong>{price},- €</strong></p>
                    }

                    <div>
                        {
                            showFeedback &&
                            <div className=" mt-6">

                                {

                                    thanks === false ?
                                        <div>
                                            <p className="mb-3">Are you satisfied with the result?</p>
                                            <div>
                                                <button className="button is-success mr-2" onClick={() => setThanks(true)}>Yes 👍</button>

                                                <button className="button is-danger" onClick={() => setThanks(true)}>No 👎</button>
                                            </div>
                                        </div>
                                        :
                                        <p>Thank you for your feedback!</p>
                                }


                            </div>
                        }


                    </div>
                    <hr />
                    <br />
                    {/* <p className="subtitle is-6">If you would like to know more about the technologies used, click on the button below and find out more?  </p> */}
                    <h4 className="title is-4">Technology stack</h4>
                    <p className="subtitle is-6">Start looking at the technology stack 🚀</p>
                    <p className="subtitle is-6"><button className="button is-primary is-outlined" onClick={() => { setActiveModal('is-active'); }}>Open technology stack&nbsp; <i className="fas fa-code"></i></button> </p>


                    <div className={`modal ${activeModal}`}>
                        <div className="modal-background" onClick={() => { setActiveModal(''); }}></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Technology stack</p>
                                <button className="delete" onClick={() => { setActiveModal(''); }} aria-label="close"></button>
                            </header>
                            <section className="modal-card-body">
                                <p>
                                    <img className="mr-3" src="https://img.shields.io/badge/pipeline--frontend-success-brightgreen" alt="Pipeline frontend status" />
                                    <img src="https://img.shields.io/badge/pipeline--backend-success-brightgreen" alt="Pipeline backend status" />
                                </p>
                                <br />
                                <h2 className='subtitle is-6'>⚠️This section is still under construction⚠️</h2>
                                <p className='has-text-justified'>Is this section interesting? Please give me your <a href="mailto:contact@lukasstoll.com">feedback</a>.</p>
                                <br />
                                <p className='has-text-weight-bold mb-3'>Features</p>
                                <ul style={{ listStyle: 'disc' }} className="ml-4" >
                                    <li>🚀 Optimised for rapid access to the price prediction</li>
                                    <li>🖊 Various features can be set to perform more accurate prediction</li>
                                </ul>
                                <br />

                                <h2 className="title is-5">Table of contents</h2>
                                <ul style={{ listStyle: 'disc' }} className="ml-4" >
                                    <li onClick={() => explanation.current.scrollIntoView(0, 0, { behavior: 'smooth'})}><a>Explanation</a></li>
                                    <li onClick={() => outlook.current.scrollIntoView(0, 0, { behavior: 'smooth'})}><a>Outlook</a></li>
                                </ul>

                                <hr />
                                <h2 className="title is-5" ref={explanation}>Explanation</h2>
                                <br />

                                <p className="has-text-justified"> Currently, the price predction is based on a pre-trained model. On the one hand, the frontend runs in a Docker container and the backend in a Docker container.
                                    The frontend sends the data entered by the user to the web server via a REST API. This runs on the basis of Node.js. This in turn sends the parameters to the pre-trained model and a price is calculated with the help of the trained model.
                                    Furthermore, everything is done via a fully automated CI/CD pipeline.</p>
                                <img src={stack} alt="Technology stack" />
                                Furthermore, it is important to improve the model. The satisfaction of the user plays an important role. If the user is not satisfied, this will be taken into account in the next training and will not be dealt with any further.
                                <p className="has-text-justified">If the user is satisfied with the result, this is taken into account in the training.</p>

                                <img src={result} alt="Result" />
                                <p className="has-text-justified">Training the model plays an important role. The model should not be trained randomly (every week on Sunday, at 3 pm, ...) but on the basis of metrics such as the conversion rate.</p>

                                <hr></hr>
                                <h2 className="title is-5" ref={outlook}>Outlook <span class="tag is-warning">New</span></h2>
                                <br />
                                <p className="has-text-justified">Furthermore, Kubernetes is to be used and monitored through the help of Grafana. The REST API is to be replaced by GraphQL.
                                    In addition, A/B testing should be used to compare the old and the newly trained model. </p>
                            </section>
                            {/* <footer className="modal-card-foot">
                                <button className="button is-success">Save changes</button>
                                <button className="button">Cancel</button>
                            </footer> */}
                        </div>
                    </div>



                </div >



            </div >
        </div >



    )
}

export default Dashboard
